<template>
  <section class="d-flex flex-column">
    <div class="d-flex align-center">
      <span class="text-body-1 font-weight-bold mr-2"> {{ team.tag }}</span>
      <span class="text-body-2 subtext--text"> - Sigla</span>
    </div>

    <div class="d-flex align-center mb-8">
      <span class="text-h5 font-weight-bold mr-2"> {{ team.name }}</span>
      <span class="text-body-2 subtext--text"> - Nome da equipe</span>
    </div>

    <div @keypress.enter.prevent="submit()" class="mb-8">
      <div class="d-flex align-center mb-3">
        <div
          class="secondary rounded-lg mr-6"
          style="height: 24px; width: 8px"
        />

        <div class="text-h6 font-weight-bold">
          <span class="saira"> Logo da equipe </span>
        </div>
      </div>

      <v-item-group v-model="form.logo" mandatory>
        <v-row>
          <v-col v-for="(item, i) in logos" :key="i" cols="6" md="3">
            <v-item v-slot="{ active, toggle }">
              <v-card
                :color="active ? 'primary' : 'accent'"
                class="d-flex align-center pa-1"
                dark
                @click="toggle"
              >
                <v-img :src="item" :aspect-ratio="4 / 4" contain />
              </v-card>
            </v-item>
          </v-col>
        </v-row>
      </v-item-group>
    </div>

    <slot name="actions" :item="{ logo: logos[form.logo] }">
      <div class="d-flex justify-end">
        <v-btn
          class="rounded-r-0 rounded-l-lg text-none mr-6"
          color="accent"
          width="128"
          large
          @click="back()"
        >
          Voltar
        </v-btn>

        <v-btn
          class="rounded-l-0 rounded-r-lg text-none"
          color="primary"
          width="128"
          large
          @click="submit()"
        >
          Finalizar
        </v-btn>
      </div>
    </slot>
  </section>
</template>

<script>
import { blockNumber } from "@/utils";

export default {
  data() {
    return {
      valid: false,
      logos: [
        "https://lolstatic-a.akamaihd.net/frontpage/apps/prod/clash-2018/pt_BR/a46e742ae82f9d4f9db8e34ba57873e513e727b7/assets/static/svg/team1.svg",
        "https://lolstatic-a.akamaihd.net/frontpage/apps/prod/clash-2018/pt_BR/a46e742ae82f9d4f9db8e34ba57873e513e727b7/assets/static/svg/team2.svg",
        "https://lolstatic-a.akamaihd.net/frontpage/apps/prod/clash-2018/pt_BR/a46e742ae82f9d4f9db8e34ba57873e513e727b7/assets/static/svg/team3.svg",
        "https://lolstatic-a.akamaihd.net/frontpage/apps/prod/clash-2018/pt_BR/a46e742ae82f9d4f9db8e34ba57873e513e727b7/assets/static/svg/team4.svg",
        "https://lolstatic-a.akamaihd.net/frontpage/apps/prod/clash-2018/pt_BR/a46e742ae82f9d4f9db8e34ba57873e513e727b7/assets/static/svg/team5.svg",
        "https://lolstatic-a.akamaihd.net/frontpage/apps/prod/clash-2018/pt_BR/a46e742ae82f9d4f9db8e34ba57873e513e727b7/assets/static/svg/team6.svg",
        "https://lolstatic-a.akamaihd.net/frontpage/apps/prod/clash-2018/pt_BR/a46e742ae82f9d4f9db8e34ba57873e513e727b7/assets/static/svg/team7.svg",
        "https://lolstatic-a.akamaihd.net/frontpage/apps/prod/clash-2018/pt_BR/a46e742ae82f9d4f9db8e34ba57873e513e727b7/assets/static/svg/team8.svg",
        "https://lolstatic-a.akamaihd.net/frontpage/apps/prod/clash-2018/pt_BR/a46e742ae82f9d4f9db8e34ba57873e513e727b7/assets/static/svg/team9.svg",
        "https://lolstatic-a.akamaihd.net/frontpage/apps/prod/clash-2018/pt_BR/a46e742ae82f9d4f9db8e34ba57873e513e727b7/assets/static/svg/team10.svg",
        "https://lolstatic-a.akamaihd.net/frontpage/apps/prod/clash-2018/pt_BR/a46e742ae82f9d4f9db8e34ba57873e513e727b7/assets/static/svg/team11.svg",
        "https://lolstatic-a.akamaihd.net/frontpage/apps/prod/clash-2018/pt_BR/a46e742ae82f9d4f9db8e34ba57873e513e727b7/assets/static/svg/team12.svg",
        "https://lolstatic-a.akamaihd.net/frontpage/apps/prod/clash-2018/pt_BR/a46e742ae82f9d4f9db8e34ba57873e513e727b7/assets/static/svg/team13.svg",
        "https://lolstatic-a.akamaihd.net/frontpage/apps/prod/clash-2018/pt_BR/a46e742ae82f9d4f9db8e34ba57873e513e727b7/assets/static/svg/team14.svg",
        "https://lolstatic-a.akamaihd.net/frontpage/apps/prod/clash-2018/pt_BR/a46e742ae82f9d4f9db8e34ba57873e513e727b7/assets/static/svg/team15.svg",
        "https://lolstatic-a.akamaihd.net/frontpage/apps/prod/clash-2018/pt_BR/a46e742ae82f9d4f9db8e34ba57873e513e727b7/assets/static/svg/team16.svg",
      ],
      form: {
        logo: 0,
      },
    };
  },

  props: {
    team: {
      type: Object,
      required: true,
    },
  },

  mounted() {
    this.initLogo();
  },

  computed: {
    rules() {
      return [(v) => !!v || "Campo Obrigatório"];
    },
  },

  methods: {
    initLogo() {
      if (!this.team) return;
      const index = this.logos.indexOf(this.team.logo);
      if (index < 0) return;

      this.form.logo = index;
    },
    back() {
      this.$emit("back");
    },

    submit() {
      this.$emit("next", this.logos[this.form.logo]);
    },

    blockNumber,
  },
};
</script>

<style></style>
