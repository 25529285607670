<template>
  <section class="d-flex flex-column">
    <div class="d-flex align-center mb-4">
      <div class="secondary rounded-lg mr-6" style="height: 24px; width: 8px" />

      <div class="text-h5 font-weight-bold">
        <span class="saira"> Equipe criada </span>
      </div>
    </div>

    <div class="d-flex align-center mb-8">
      <v-avatar class="mr-6" size="96" rounded>
        <v-img :src="team.logo" contain />
      </v-avatar>

      <div class="d-flex flex-column">
        <div class="d-flex align-center">
          <span class="text-body-1 font-weight-bold mr-2"> {{ team.tag }}</span>
          <span class="text-body-2 subtext--text"> - Sigla</span>
        </div>

        <div class="d-flex align-center">
          <span class="text-h5 font-weight-bold mr-2">{{ team.name }}</span>
          <span class="text-body-2 subtext--text">- Nome da equipe</span>
        </div>
      </div>
    </div>

    <div class="text-h6 font-weight-bold mb-2">
      <span class="saira"> Convide Jogadores </span>
    </div>

    <span class="text-body-2 mb-8">
      Copie e envie o link de convite para todos os jogadores que deseja que
      faça parte da sua equipe.
    </span>

    <v-text-field
      v-model="inviteUrl"
      id="code"
      background-color="accent"
      readonly
      solo
      flat
      @focus="copy('code')"
    >
      <template v-slot:append>
        <PrimaryButton
          :text="'Copiar'"
          :icon="'mdi-content-copy'"
          outlined
          @click="copy('code')"
        />
      </template>
    </v-text-field>

    <div class="d-flex justify-end pa-2 mx-1">
      <PrimaryButton
        :icon="'mdi-arrow-right'"
        :text="'Ir para Perfil da Equipe'"
        @click="handleTeamProfile"
      />
    </div>
  </section>
</template>

<script>
import { blockNumber } from "@/utils";
import PrimaryButton from "@/components/buttons/PrimaryButton";

export default {
  components: { PrimaryButton },
  props: {
    team: {
      type: Object,
      required: true,
    },
  },

  computed: {
    inviteUrl() {
      return (
        this.$store.getters.appURL + "/teams/new?invite_code=" + this.team.code
      );
    },
  },

  methods: {
    // copy do clipboard
    copy(value) {
      const textBox = document.getElementById(value);
      textBox.select();

      document.execCommand("copy");
    },

    handleTeamProfile() {
      this.$router.push({ path: `/team-details/${this.team.id}` });
    },

    blockNumber,
  },
};
</script>

<style></style>
