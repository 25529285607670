<template>
  <v-container class="py-12">
    <div
      class="d-flex flex-column flex-md-row align-start align-md-center mb-8"
    >
      <v-btn class="px-1 mr-6 text-none mb-2 mb-md-0" plain @click="back()">
        <v-avatar size="26" color="primary" class="mr-2" rounded>
          <v-icon small>mdi-arrow-left</v-icon>
        </v-avatar>

        <span> Voltar </span>
      </v-btn>

      <div class="d-flex flex-column">
        <div class="text-h4 font-weight-bold mb-2">
          <span class="saira"> Monte sua equipe </span>
        </div>

        <div class="d-flex">
          <span class="text-body-1">
            Monte uma equipe vencedora para aniquilar todos os oponentes.
          </span>
        </div>
      </div>
    </div>

    <section>
      <v-row>
        <v-col cols="12" md="8">
          <v-card class="pa-4 pa-md-16" color="surface" flat tile>
            <v-window v-model="window" vertical>
              <!-- join or create -->
              <v-window-item :key="0">
                <ModeSelect @join-team="window = 1" @create-team="window = 2" />
              </v-window-item>

              <!-- join -->
              <v-window-item :key="1">
                <JoinTeam
                  :inviteCode="joinForm.code"
                  @back="window = 0"
                  @next="
                    joinForm.code = $event.code;
                    joinSubmit();
                  "
                />
              </v-window-item>

              <!-- create -->
              <v-window-item :key="2">
                <TeamIdentification
                  @back="window = 0"
                  @next="
                    createForm.tag = $event.tag;
                    createForm.name = $event.name;
                    createForm.password = $event.password;
                    window = 3;
                  "
                />
              </v-window-item>

              <!-- avatar -->
              <v-window-item :key="3">
                <TeamAvatar
                  :team="createForm"
                  @back="window = 2"
                  @next="
                    createForm.logo = $event;
                    handleRequestConfirm();
                  "
                />
              </v-window-item>

              <!-- resume -->
              <v-window-item :key="4">
                <TeamResume :team="team" />
              </v-window-item>
            </v-window>
          </v-card>
        </v-col>

        <v-col cols="12" md="6"></v-col>
      </v-row>
    </section>

    <ProofDialog ref="proofDialog" :lockClose="true" />

    <JoinLeagueConfirmation
      v-if="!!league"
      ref="confirm"
      :item="league"
      @confirm="createSubmit()"
    />

    <loader-hover v-if="loading" />
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { createTeam, joinTeamCode } from "@/services/teams";
import { getLeague } from "@/services/leagues";
import { displayAlert } from "@/utils";
import ModeSelect from "@/components/teams/ModeSelect.vue";
import JoinTeam from "@/components/teams/JoinTeam.vue";
import TeamIdentification from "@/components/teams/TeamIdentification.vue";
import TeamAvatar from "@/components/teams/TeamAvatar.vue";
import TeamResume from "@/components/teams/TeamResume.vue";
import JoinLeagueConfirmation from "@/components/leagues/JoinLeagueConfirmation";
import ProofDialog from "@/components/leagues/ProofDialog.vue";

export default {
  data() {
    return {
      loading: false,
      window: 0,
      createForm: {
        tag: "",
        name: "",
        password: "",
        logo: "",
      },
      joinForm: {
        code: "",
      },
      team: null,
      league: null,
    };
  },

  components: {
    JoinLeagueConfirmation,
    ModeSelect,
    JoinTeam,
    TeamIdentification,
    TeamAvatar,
    TeamResume,
    ProofDialog,
  },

  beforeMount() {
    this.getData();

    if (this.$route.query.invite) {
      this.window = 1;
    }

    if (this.$route.query.invite_code) {
      this.window = 1;
      this.joinForm.code = this.$route.query.invite_code;
    }
  },

  mounted() {
    if (!this.localUser.schoolCertificate)
      this.$refs.proofDialog.handleDialog();
  },

  computed: {
    ...mapState(["localUser"]),

    leagueId: function () {
      return this.$route.query.leagueId;
    },
  },

  methods: {
    async createSubmit() {
      if (!this.leagueId) return;

      try {
        this.loading = true;

        const payload = {
          ...this.createForm,
          leagueId: this.leagueId,
        };

        await createTeam(payload).then((res) => {
          this.team = res;
          this.window = 4;
        });
      } catch (err) {
        this.displayAlert(err.data.message, 1);
      } finally {
        this.loading = false;
      }
    },

    async joinSubmit() {
      try {
        this.loading = true;

        const payload = this.joinForm;

        await joinTeamCode(payload).then(() => {
          this.$router.push({ path: "/account/teams" });
        });
      } catch (err) {
        this.displayAlert(err.data.message, 1);
      } finally {
        this.loading = false;
      }
    },

    async getData() {
      try {
        await getLeague(this.leagueId).then((data) => (this.league = data));
      } catch (err) {
        this.displayAlert("Liga não encontrada", 1);
        this.$router.push({ path: "/leagues" });
      } finally {
        this.loading = false;
      }
    },

    handleRequestConfirm() {
      const element = this.$refs.confirm;
      if (!element) return;

      element.dialog = true;
    },

    back() {
      this.$router.go("-1");
    },

    displayAlert,
  },
};
</script>

<style></style>
