<template>
  <section class="d-flex flex-column">
    <v-form v-model="valid" ref="form" autocomplete="off">
      <div @keypress.enter.prevent="submit()" class="mb-4">
        <section>
          <div class="d-flex align-center mb-3">
            <div
              class="secondary rounded-lg mr-6"
              style="height: 24px; width: 8px"
            />

            <div class="text-h6 font-weight-bold">
              <span class="saira"> Sigla da equipe </span>
            </div>
          </div>

          <v-text-field
            v-model="form.tag"
            v-mask="'AAA'"
            background-color="accent"
            :rules="tagRules"
            solo
            flat
          />
        </section>

        <section>
          <div class="d-flex align-center mb-3">
            <div
              class="secondary rounded-lg mr-6"
              style="height: 24px; width: 8px"
            />

            <div class="text-h6 font-weight-bold">
              <span class="saira"> Nome da equipe </span>
            </div>
          </div>

          <v-text-field
            v-model="form.name"
            background-color="accent"
            :rules="nameRules"
            solo
            flat
            @keypress="blockNumber($event)"
          />
        </section>
      </div>

      <slot
        class="d-flex justify-end"
        name="actions"
        :item="{ tag: form.tag, name: form.name }"
      >
        <div class="d-flex justify-end">
          <v-btn
            class="rounded-r-0 rounded-l-lg text-none mr-6"
            color="accent"
            width="128"
            depressed
            large
            @click="back()"
          >
            Voltar
          </v-btn>

          <v-btn
            class="rounded-l-0 rounded-r-lg text-none"
            color="primary"
            width="128"
            depressed
            large
            @click="submit()"
          >
            Próximo
          </v-btn>
        </div>
      </slot>
    </v-form>
  </section>
</template>

<script>
import { mask } from "vue-the-mask";
import { blockNumber } from "@/utils";

export default {
  data() {
    return {
      valid: false,
      form: {
        tag: "",
        name: "",
      },
    };
  },

  directives: {
    mask,
  },

  computed: {
    tagRules() {
      return [
        (v) => !!v || "Campo Obrigatório",
        (v) => v.length >= 3 || "Sigla inválida",
      ];
    },

    nameRules() {
      return [
        (v) => !!v || "Campo Obrigatório",
        (v) => v.length >= 3 || "Nome inválida",
      ];
    },
  },

  methods: {
    back() {
      this.$emit("back");
    },

    submit() {
      if (!this.$refs.form.validate()) return;

      this.$emit("next", this.form);
    },

    blockNumber,
  },
};
</script>

<style></style>
