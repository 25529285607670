<template>
  <section class="d-flex flex-column align-center">
    <div class="d-flex align-center text-center mb-12">
      <div class="secondary rounded-lg mr-6" style="height: 24px; width: 8px" />

      <div class="text-h6 font-weight-bold">
        <span class="saira"> Escolha uma das opções abaixo </span>
      </div>
    </div>

    <v-btn
      class="rounded-l-0 rounded-r-lg text-none mb-8"
      width="200"
      color="primary"
      large
      @click="$emit('create-team')"
    >
      Crie uma equipe
    </v-btn>

    <v-btn
      class="rounded-l-0 rounded-r-lg text-none"
      width="200"
      color="primary"
      outlined
      large
      @click="$emit('join-team')"
    >
      Entre em uma equipe
    </v-btn>
  </section>
</template>

<script>
export default {};
</script>

<style></style>
